<script>
	export let label = 'label';

	/** @type {any} */
	export let value = undefined;

	/** @type {string | null} */
	export let name = null;

	/** @type {boolean} */
	export let required = false;
</script>

<input
	placeholder={label}
	{required}
	type="text"
	class="p-2 border border-gray-300 bg-gray-100 w-full"
	{name}
	bind:value
/>
