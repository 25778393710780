<script>
	import TextInput from '$lib/components/TextInput.svelte';
	import { page } from '$app/stores';
	import { browser } from '$app/environment';

	// https://stackoverflow.com/a/75670095
	$: request = browser ? $page.url.searchParams.get('request') : undefined;
</script>

<form name="request-demo" method="POST" action="https://api.shape.exchange/request-demo">
	<div class="flex items-stretch text-sm">
		<input type="hidden" name="context" value="www" />
		<TextInput required name="email" label="Email" />
		<button type="submit" class="bg-highlight-alt px-8">Sign&nbsp;up</button>
	</div>
	{#if request === 'complete'}
		<div class="pt-5">
			Thank you for your interest in ShapeExchange. We will contact you shortly.
		</div>
	{:else}
		<div class="pt-3 text-sm">
			Already have an account? <a href="https://app.shape.exchange">Sign in</a>.
		</div>
	{/if}
</form>

<style>
	a {
		text-decoration: underline;
	}
</style>
