<script>
	/**
	 * @type {string}
	 */
	export let id;

	/**
	 * @type {string | null}
	 */
	export let height = null;
</script>

<section {id} style={height ? `height:${height};` : null}>
	<div class="px-8 lg:px-16 py-4 gap-8 mx-auto my-4 md:my-16 max-w-7xl">
		<div class="flex flex-col md:justify-between md:flex-row gap-10">
			<slot />
		</div>
	</div>
</section>

<style lang="scss">
	section:nth-child(odd) {
		@apply bg-gray-100;
	}
</style>
